/*!

 =========================================================
 * Paper Dashboard PRO React - v1.1.0 based on Paper Dashboard 2 Pro - v2.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import "paper-dashboard/variables";
@import "paper-dashboard/mixins";

// Plugins CSS
@import "paper-dashboard/plugins/plugin-bootstrap-switch";
@import "paper-dashboard/plugins/plugin-bootstrap-table";
@import "paper-dashboard/plugins/plugin-nouislider";
@import "paper-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "paper-dashboard/plugins/plugin-perfect-scrollbar";
@import "paper-dashboard/plugins/plugin-sweetalert2";
@import "paper-dashboard/plugins/plugin-card-wizard";
@import "paper-dashboard/plugins/plugin-datetimepicker";
@import "paper-dashboard/plugins/plugin-bootstrap-select";
@import "paper-dashboard/plugins/plugin-jasny-fileupload";
@import "paper-dashboard/plugins/plugin-tagsinput";
@import "paper-dashboard/plugins/plugin-datatables.net";
@import "paper-dashboard/plugins/plugin-jquery.jvectormap";
@import "paper-dashboard/plugins/plugin-fullcalendar";

// Core CSS
@import "paper-dashboard/nucleo-outline";
@import "paper-dashboard/buttons";
@import "paper-dashboard/social-buttons";
@import "paper-dashboard/animated-buttons";
@import "paper-dashboard/inputs";
@import "paper-dashboard/typography";
@import "paper-dashboard/misc";
@import "paper-dashboard/misc-extend";
@import "paper-dashboard/navbar";
@import "paper-dashboard/dropdown";
@import "paper-dashboard/alerts";
@import "paper-dashboard/images";
@import "paper-dashboard/tables";
@import "paper-dashboard/footers";
@import "paper-dashboard/fixed-plugin";

// components
@import "paper-dashboard/checkboxes-radio";
@import "paper-dashboard/progress";
@import "paper-dashboard/badges";
@import "paper-dashboard/pagination";
@import "paper-dashboard/info-areas";
@import "paper-dashboard/pills";
@import "paper-dashboard/tabs";
@import "paper-dashboard/popups";
@import "paper-dashboard/modals";
@import "paper-dashboard/carousel";
@import "paper-dashboard/sidebar-and-main-panel";
@import "paper-dashboard/timeline";

// cards
@import "paper-dashboard/cards";
@import "paper-dashboard/cards/card-chart";
@import "paper-dashboard/cards/card-map";
@import "paper-dashboard/cards/card-user";

@import "paper-dashboard/cards/card-background";
@import "paper-dashboard/cards/card-collapse";
@import "paper-dashboard/cards/card-contributions";
@import "paper-dashboard/cards/card-info-area";
@import "paper-dashboard/cards/card-lock";
@import "paper-dashboard/cards/card-pricing";
@import "paper-dashboard/cards/card-profile";
@import "paper-dashboard/cards/card-signup";
@import "paper-dashboard/cards/card-stats-mini";
@import "paper-dashboard/cards/card-stats";
@import "paper-dashboard/cards/card-subcategories";
@import "paper-dashboard/cards/card-testimonials";
@import "paper-dashboard/cards/card-tasks";

//overrides, additions
@import "paper-dashboard/overrides/overrides";

// example pages and sections
@import "paper-dashboard/example-pages";
@import "paper-dashboard/sections";

@import "paper-dashboard/responsive";
@import "paper-dashboard/media-queries";

// react differences
@import "paper-dashboard/react/react-differences";

// custom
@import "paper-dashboard/custom";

// pages
@import "paper-dashboard/pages/index";

// normal styles for each component will be here

@import "./paper-dashboard/components";

.sidebar {
    background-image: url('/assets/img/sp-art-1.png');
}

.full-page {
    background-image: url('/assets/img/sp-art-2.png');
}

.navbar {
    a {
        color: $black-color !important;
    }
}

.liaccount {
    cursor: pointer !important;
}

// CUSTOM CSS
.my-btn {
    font-weight:bold;
    border: solid 2px white;
    border-radius: 2em;
    text-transform: none;
    &:hover {
        color: black !important;
        border-color: black;
    }
}

.blue-left-area {
    width: 34vw;
    background: #1B7BBD;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 3;
}

.blue-left-area-text {
    background: #1B7BBD;
    flex: 1;
    z-index: 4;
    margin-top: 2em;
}

.login-area {
    padding: 0 3em 3em 5em;

    p {
        color: white;
        margin-bottom: 4em;
    }

    h2 {
        color: white;
        span {
            font-weight: bold;
            font-size: 1.1em;
        }
    }
}

.my-login-page {
    width: 100%;
    flex: 2;
    margin-top: 4em;

    h3 {
        margin-bottom: 0;
        font-weight: bold;
    }

    .my-under-blue {
        width: 4.5em;
        height: .3em;
        margin: auto;
        background-color: $primary-color;
        margin-bottom: 2em;
        border-radius: 1em;
    }

    .input-group-text {
        border: none;
    }

    .form-control {
        border: none;
    }

    .my-btn-2 {
        margin-top: 2.5em !important;
        margin-bottom: 3em !important;
        background: $dark-background;
        width: 50%;
    }
    .card {
        //background: transparent;
        box-shadow: none;
    }

    .input-group {
        background: transparent !important;
        border-bottom: 2px solid $dark-background;
        margin-bottom: 4em;
    }

    .mike {
        margin-bottom: 10em;
    }
}

// REGISTER
.plans {
    border: 2px solid black;
    display: flex;
    border-radius: 2em;
    margin-bottom: 1em;

    a {
        flex: 1;
        text-align: center;
        color: black;
        border-radius: 2em;
        padding: .4em;

        &:hover {
            color: rgb(131, 131, 131);
        }

        &.active {
            color: white;
            background-color: $primary-color;
        }

    }
}

.aw {
    width: 7em;
    position: absolute;

    &.aw1 {
        left:23vw;
        top: 2em;
    }

    &.aw2 {
        width: 20em;
        bottom: 0;
        left: 0;
    }
}

.btn-success {
    background: $primary-color;
}


@media only screen and (max-width: 915px) {

    .mobile-message {
        display: block !important;
        text-align: center;
        h2 {
            font-size: 1em;
            color: white;
            span {
                font-weight: bold;
            }
        }
    }

    .aw.aw1 {
        left: 60vw;
    }

    .blue-left-area {
        width: 100%;
    }

    .blue-left-area-text {
        display: none;
    }

    .my-login-page {
        position: absolute;
        z-index: 6;
        margin-top: 1em;
    }
}
