.msging-modal {
  .modal-content {
    height: auto;
    display: flex;
    flex-direction: column;
  }

	.last-message {
		white-space: nowrap;
		padding-bottom: .5rem;
		padding-right: 1.6rem;
		padding-left: .3rem;

		p {
			margin: 0;
			margin-bottom: 0px;
			text-overflow: ellipsis;
			overflow: hidden;
			padding-top: 5px;
		}
		.msg-content {
			padding: 10px 0;
		}
	}
	.tags-content {
		display: flex;
		justify-content: space-between;
	}	
	.messages {
		max-height: 350px;
		overflow: scroll;
  }
  .maxwidth-avatar {
    height: 35px;
    width: 40px;
    border-radius: 50%;
  }
  .send-message {
    display: flex;
    align-items: center;
  }
}

.card-chat{
	.header{
		padding:20px;
		padding-bottom:0;
	}
	.card-title{
		margin-top:0;
	}
	.category{font-size:14px;}
}

.main-panel{
  .header{
    margin-bottom:0;
  }
}