.msg-container {

  .msg-text {
    padding: 0px 17px 8px 17px;
    font-size: 14px;
  }
  .msg-timestamp {
    font-size: 10px;
  }
  .self {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;

    .msg {
      order: 1;
      border: 2px solid #d6c1ab;
      border-radius: 16px;
      position: relative;
      background-color: #d6c1ab;
      padding: 0.7rem;
      margin-bottom: 0;

      &:after {
        content: "";
        position: absolute;
        display: inline-block;
        bottom: 19px;
        right: -17px;
        width: 0px;
        height: 0px;
        border: 8px solid #d6c1ab;
        border-right-color: transparent;
        border-top-color: transparent;
      }
    }
  }
  .other {
    .msg {
      order: 1;
      border: 2px solid #b8d8d8;
      border-radius: 16px;
      position: relative;
      background-color: #b8d8d8;
      padding: 0.7rem;
      margin-bottom: 0;

      &:before {
        content: "";
        position: absolute;
        top: 15px;
        left: -16px;
        height: 0px;
        border: 8px solid #b8d8d8;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }

  .msg-topBar {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 4px;

    .right-block {
      display: none;
      align-items: flex-end;
      margin-bottom: 25px;
    }
  }

  .end {
    justify-content: flex-end;
  }

  &.received-container {
    .left-block {
      display: none;
    }
    .right-block {
      display: flex;
    }
  }
}
