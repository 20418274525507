.link {
  color: #51cbce;
  &:hover {
    cursor: pointer;
  }
}
.maxwidth-avatar {
  max-width: 100% !important;
}
.send-msg-sub-container {
  padding: 0px 16px 0px 16px;

  .required-subject {
    label {
      color: red;
      margin: 0;
      padding: 0;
    }
  }
}
.prospects-table {
  .activity-badges {
    line-height: normal;
    .el-inline {
      font-size: 0.9rem;
      .badge {
        margin-bottom: 0;
      }
    }
  }
}
.camp-prospects-description {
  p {
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }
}
.camp-seq-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.send-msg-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 10px;

  .send-button {
    padding-right: 5px;
  }
  .input {
    width: 85% !important;
    height: 38px !important;
    // max-height: 40px
  }
  .avatar {
    margin: 5px;
    margin-right: 8px;
    min-width: 30px;
  }
  .reply-error-msg {
    text-transform: capitalize;
    padding-bottom: 10px;
  }
  .form-control {
    background-color: #f3f2ee;
    border: 1px solid #e8e7e3;
    border-radius: 4px;
    color: #66615b;
    font-size: 14px;
    padding: 7px 18px;
    height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .message-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 3px;

    .emoji-wrapper {
      background-color: dimgray;
      padding: 8px;
      border: none;
      border-radius: 30px;
      outline: none;
    }
    .intercom-composer-popover-input {
      font-size-adjust: none;
      font-size: 100%;
      font-style: normal;
      letter-spacing: normal;
      font-stretch: normal;
      font-variant: normal;
      font-weight: 400;
      font: normal normal 100% "intercom-font", "Helvetica Neue", Helvetica,
        Arial, sans-serif;
      text-align: left;
      text-align-last: auto;
      text-decoration: none;
      -webkit-text-emphasis: none;
      text-emphasis: none;
      text-height: auto;
      text-indent: 0;
      text-justify: auto;
      text-outline: none;
      text-shadow: none;
      text-transform: none;
      text-wrap: normal;
      alignment-adjust: auto;
      alignment-baseline: baseline;
      -webkit-animation: none 0 ease 0 1 normal;
      animation: none 0 ease 0 1 normal;
      -webkit-animation-play-state: running;
      animation-play-state: running;
      -webkit-appearance: normal;
      -moz-appearance: normal;
      appearance: normal;
      azimuth: center;
      -webkit-backface-visibility: visible;
      backface-visibility: visible;
      background: none 0 0 auto repeat scroll padding-box transparent;
      background-color: transparent;
      background-image: none;
      baseline-shift: baseline;
      binding: none;
      bleed: 6pt;
      bookmark-label: content();
      bookmark-level: none;
      bookmark-state: open;
      bookmark-target: none;
      border: 0 none transparent;
      border-radius: 0;
      bottom: auto;
      box-align: stretch;
      -webkit-box-decoration-break: slice;
      box-decoration-break: slice;
      box-direction: normal;
      box-flex: 0;
      box-flex-group: 1;
      box-lines: single;
      box-ordinal-group: 1;
      box-orient: inline-axis;
      box-pack: start;
      box-shadow: none;
      box-sizing: content-box;
      -webkit-column-break-after: auto;
      break-after: auto;
      -webkit-column-break-before: auto;
      break-before: auto;
      -webkit-column-break-inside: auto;
      break-inside: auto;
      caption-side: top;
      clear: none;
      clip: auto;
      color: inherit;
      color-profile: auto;
      -webkit-column-count: auto;
      -moz-column-count: auto;
      column-count: auto;
      -webkit-column-fill: balance;
      -moz-column-fill: balance;
      column-fill: balance;
      -webkit-column-gap: normal;
      -moz-column-gap: normal;
      column-gap: normal;
      -webkit-column-rule: medium medium #1f1f1f;
      -moz-column-rule: medium medium #1f1f1f;
      column-rule: medium medium #1f1f1f;
      -webkit-column-span: 1;
      -moz-column-span: 1;
      column-span: 1;
      -webkit-column-width: auto;
      -moz-column-width: auto;
      column-width: auto;
      -webkit-columns: auto auto;
      -moz-columns: auto auto;
      columns: auto auto;
      content: normal;
      counter-increment: none;
      counter-reset: none;
      crop: auto;
      cursor: auto;
      direction: ltr;
      display: inline;
      dominant-baseline: auto;
      drop-initial-after-adjust: text-after-edge;
      drop-initial-after-align: baseline;
      drop-initial-before-adjust: text-before-edge;
      drop-initial-before-align: caps-height;
      drop-initial-size: auto;
      drop-initial-value: initial;
      elevation: level;
      empty-cells: show;
      fit: fill;
      fit-position: 0 0;
      float: none;
      float-offset: 0 0;
      grid-columns: none;
      grid-rows: none;
      hanging-punctuation: none;
      height: auto;
      hyphenate-after: auto;
      hyphenate-before: auto;
      hyphenate-character: auto;
      hyphenate-lines: no-limit;
      hyphenate-resource: none;
      -webkit-hyphens: manual;
      -ms-hyphens: manual;
      hyphens: manual;
      icon: auto;
      image-orientation: auto;
      image-rendering: auto;
      image-resolution: normal;
      inline-box-align: last;
      left: auto;
      line-height: inherit;
      line-stacking: inline-line-height exclude-ruby consider-shifts;
      list-style: disc outside none;
      margin: 0;
      marks: none;
      marquee-direction: forward;
      marquee-loop: 1;
      marquee-play-count: 1;
      marquee-speed: normal;
      marquee-style: scroll;
      max-height: none;
      max-width: none;
      min-height: 0;
      min-width: 0;
      move-to: normal;
      nav-down: auto;
      nav-index: auto;
      nav-left: auto;
      nav-right: auto;
      nav-up: auto;
      opacity: 1;
      orphans: 2;
      outline: medium none invert;
      outline-offset: 0;
      overflow: visible;
      overflow-style: auto;
      padding: 0;
      page: auto;
      page-break-after: auto;
      page-break-before: auto;
      page-break-inside: auto;
      page-policy: start;
      -webkit-perspective: none;
      perspective: none;
      -webkit-perspective-origin: 50% 50%;
      perspective-origin: 50% 50%;
      pointer-events: auto;
      position: static;
      presentation-level: 0;
      punctuation-trim: none;
      quotes: none;
      rendering-intent: auto;
      resize: none;
      right: auto;
      rotation: 0;
      rotation-point: 50% 50%;
      ruby-align: auto;
      ruby-overhang: none;
      ruby-position: before;
      ruby-span: none;
      size: auto;
      string-set: none;
      table-layout: auto;
      top: auto;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      -webkit-transform-origin: 50% 50% 0;
      -ms-transform-origin: 50% 50% 0;
      transform-origin: 50% 50% 0;
      -webkit-transform-style: flat;
      transform-style: flat;
      transition: all 0 ease 0;
      unicode-bidi: normal;
      vertical-align: baseline;
      white-space: normal;
      white-space-collapse: collapse;
      widows: 2;
      width: auto;
      word-break: normal;
      word-spacing: normal;
      word-wrap: normal;
      z-index: auto;
      text-align: start;
      -ms-filter: "progid:DXImageTransform.Microsoft.gradient(enabled=false)";
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .intercom-composer-popover {
      z-index: 2147483003;
      position: absolute;
      bottom: 50px;
      right: 0; //calc(50% - 165px);
      box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.08);
      background-color: #fff;
      border-radius: 6px;
      transition-duration: 200ms;
      transition-delay: 0ms;
      transform-style: flat;
      transform-origin: 50% 50% 0;
      opacity: 0;
      transition: all 0.2s linear;
      visibility: hidden;
    }
    .intercom-composer-popover.active {
      visibility: visible;
      opacity: 1;
      bottom: 45px;
    }
    .intercom-emoji-picker {
      width: 280px;
      height: 280px;
      .gif-picker-container {
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;

        input {
          display: none;
        }

        label {
          display: inline-block;
          padding: 5px 25px;
          font-weight: 600;
          text-align: center;
          border-bottom: 1px solid transparent;
          transition: all 0.3s ease-out 0.1s;
          width: 50%;
        }
        label:hover {
          // color: #fff;
          cursor: pointer;
        }
        // input:checked + label {
        //   background: #ffffff;
        //   color: #31a9e5;
        //   border-bottom: 1px solid;
        // }

        .active {
          background: #ffffff;
          color: #31a9e5;
          border-bottom: 1px solid;
        }
      }
    }
    .intercom-composer-popover-header {
      position: absolute;
      top: 0;
      left: 20px;
      right: 20px;
      height: 40px;
      border-bottom: 1px solid #edeff1;
    }
    .intercom-composer-popover-input {
      background-image: url(https://js.intercomcdn.com/images/search.7ae40c25.png);
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: 0 12px;
      font-weight: 400;
      font-size: 14px;
      color: #6e7a89;
      padding-left: 25px;
      height: 40px;
      width: 100%;
      box-sizing: border-box;
      background-image: url(https://js.intercomcdn.com/images/search@2x.9f02b9f3.png);
      border: none;
      outline: none;
    }
    .intercom-composer-popover-body {
      position: absolute;
      top: 70px;
      left: 0;
      right: 0;
      bottom: 5px;
      padding: 0 20px;
      overflow-y: scroll;
    }
    .intercom-emoji-picker-group {
      margin: 10px -5px;
    }
    .intercom-emoji-picker-group {
      margin: 10px -5px;
    }
    .intercom-emoji-picker-group-title {
      color: #b8c3ca;
      font-weight: 400;
      font-size: 13px;
      margin: 5px;
    }
    .intercom-emoji-picker-emoji {
      padding: 5px;
      width: 30px;
      line-height: 30px;
      display: inline-table;
      text-align: center;
      cursor: pointer;
      vertical-align: middle;
      font-size: 28px;
      transition: -webkit-transform 60ms ease-out;
      transition: transform 60ms ease-out;
      transition: transform 60ms ease-out, -webkit-transform 60ms ease-out;
      transition-delay: 60ms;
      font-family: Apple Color Emoji, Segoe UI Emoji, NotoColorEmoji,
        Segoe UI Symbol, Android Emoji, EmojiSymbols;
    }

    .intercom-emoji-picker-gif {
      padding: 5px;
      width: 80px;
      display: inline-table;
      cursor: pointer;
      vertical-align: middle;
      transition: -webkit-transform 60ms ease-out;
      transition: transform 60ms ease-out;
      transition: transform 60ms ease-out, -webkit-transform 60ms ease-out;
      transition-delay: 60ms;
    }

    .intercom-emoji-picker-gif:hover {
      transition-delay: 0ms;
      -webkit-transform: scale(1.4);
      -ms-transform: scale(1.4);
      transform: scale(1.4);
    }

    .intercom-emoji-picker-emoji:hover {
      transition-delay: 0ms;
      -webkit-transform: scale(1.4);
      -ms-transform: scale(1.4);
      transform: scale(1.4);
    }
    .intercom-composer-popover-caret {
      position: absolute;
      bottom: -8px;
      right: 0;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #fff;
      right: 30px;
      display: none;
    }
  }
}
.content {
  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.Integrationlist,
.campaignlist {
  .container {
    padding-left: 0;
  }
  .card {
    padding: 15px;

    .form-check-label {
      font-size: 0.9rem;
    }
    h5 {
      color: black;
    }
  }
}
.clickable-card {
  .card-body {
    padding: 0.5rem;
  }
  .card-title {
    font-size: 1.2em;
  }
}
.cover {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.3;
}
.invalid-feedback {
  font-size: 0.9rem;
}
.valid-feedback {
  font-size: 0.9rem;
}
.success-message {
  color: green !important;
  font-size: 0.9rem !important;
}
.input-range {
  .input-range__label--min {
    visibility: hidden;
  }
  .input-range__label--max {
    visibility: hidden;
  }
}

.jsx-4179805763 {
  width: 100%;
}

ul.jsx-4179805763 {
  bottom: -120px;
  background: white;
  z-index: 101;
}
.disabled-card {
  opacity: 0.8;
}
.logo {
  .logo-img {
    width: auto;
    margin: 10px auto;
    text-align: center;
  }
}
.card-signup {
  margin-top: 60px;
}
.input-range__track--active,
.input-range__slider {
  background: $primary-color !important;
  border-color: $primary-color !important;
}

:not(.modal-content, .tab-pane) > .css-79elbk {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
textarea::-webkit-input-placeholder {
  color: #c9c9c9 !important;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #c9c9c9 !important;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #c9c9c9 !important;
}

textarea:-ms-input-placeholder {
  color: #c9c9c9 !important;
}

textarea::placeholder {
  color: #c9c9c9 !important;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent !important;
}
textarea:focus::-moz-placeholder {
  color: transparent !important;
}
textarea:focus:-moz-placeholder {
  color: transparent !important;
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sidebar {
  .nav {
    p {
      font-size: 0.9rem;
    }
  }
}
.progess-container {
  margin-top: 10px;

  .progress-value {
    padding-left: 5px;
  }
}

.run-immediately {
  .descriptor {
    margin-bottom: 0;
    display: inline-block;
  }
  i {
    font-size: 16px;
    padding: 5px;
    display: inline;
  }
  .nc-alert-circle-i {
    margin-left: 5px;
  }
}

.sweet-alert {
  p {
    margin-bottom: 0;
  }
}
.campaign-builder-card {
  .disabled {
    color: #e0e0e0 !important;
    font-style: italic;
  }
}
.camp-status-text {
  display: block;
  font-size: 10px;
}

.gray-text {
  font-weight: 400;
  color: #9a9a9a;
  font-size: 1em;
}
.sequence-builder {
  .card-body {
    text-align: left;
  }
}
.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}
.timeline .timeline-badge {
  width: 20px !important;
  height: 20px !important;
  margin-left: -10px !important;
  top: 30px !important;
}
.prospects-table,
.campaign-manager-table,
.sequence-manager-table {
  .rt-td {
    font-weight: 100;
    font-size: 16px;
  }
}
.prospects-table {
  .rt-thead {
    text-align: left;
  }
}

//CAMPAIGN DETAIL PAGE
.campaign-detail-card {
  &:hover {
    cursor: pointer;
    box-shadow: 0 10px 14px 0px rgba(0, 0, 0, 0.15);
  }
}

//LI ACCOUNTS PAGE

.accountlist {
  .card-header {
    h4 {
      margin-top: 0;
    }
  }
}
.lipage-stats {
  font-size: 1rem;
  padding: 10px 0 10px !important;
  margin: 0;
  line-height: normal;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}

.liaccount {
  cursor: pointer;
  border: 1px solid white;

  .liac-bottom {
    padding: 10px 0;

    span {
      font-size: 0.5rem;
    }
  }
  .close-btn {
    padding: 7px;
  }

  &:hover {
    border: 1px solid #e0e0e0;
  }

  .li-user-info {
    padding-left: 5px;
    padding-top: 10px;
  }

  .card-body {
    padding: 0 15px;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h3 {
    margin: 0;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .photo {
    border-radius: 50%;
  }

  .svg-inline--fa {
    height: 1.3em;
    width: 1.3em !important;
    cursor: pointer;
    &:hover {
      color: $info-color;
    }
  }
}
.liaccount.disabled-card {
  cursor: auto;
  .liac-top {
    opacity: 0.3;
  }
  .liac-bottom {
    span {
      opacity: 0.3;
    }
    span.re-login {
      opacity: 1;
      float: right;
      cursor: pointer;
    }
  }
}
.liaccount-new {
  cursor: pointer;

  &:hover {
    border: 1px solid #e0e0e0;
  }
}

.pros-btn-disabled {
  opacity: 0.2;
}

.dash-daily-table {
  .table-responsive {
    padding-bottom: 0;
  }
  .ReactTable {
    .-pagination {
      .-pageInfo {
        display: none;
      }
    }
  }
}
.prospects-table {
  .ReactTable {
    .-pagination {
      .-pageInfo {
        display: none;
      }
    }
  }
}

.badge-brown-border {
  border-color: #66615b;
  color: #66615b;
}

.badge-blue-border {
  border-color: #51bcda;
  color: #51bcda;
}

.badge-green-border {
  border-color: #6bd098;
  color: #6bd098;
}

.activity-badges {
  white-space: normal;
}
.canceled-msg {
  background: aliceblue;
  padding: 25px;
  margin: 10px;
  border-radius: 10px;
}
.cardNumber {
  width: 60%;
  display: inline-block;
}
.cvc,
.expiry {
  width: 20%;
  display: inline-block;
}

.strip-portal-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
