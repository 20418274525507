.user-info {
  display: flex;
  align-items: center;
  padding: 0 10px;

  .user-avatar-wrapper {
    width: 50px;
    border-radius: 50%;
    overflow: hidden;

    .avatar {
      height: 100%;
      width: 100%;
      margin-bottom: 0;
    }
  }

  .user-name {
    font-weight: 600;
    font-size: 16px;
  }
}
