.page-prospect {
  padding: 30px 30px 0px;

  &__header-title {
    margin-bottom: 0px;
  }

  &__add-btn{
    margin-left: 0px !important;
  }

  .prospect-link-field input{
    border-left: 1px solid #DDDDDD !important;

    &:active, &:focus {
      border-left: 1px solid #9A9A9A !important;
    }
  }
}