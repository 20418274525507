.page-prospect {
  min-height: calc(100vh - 132px);
  .css-79elbk {
    display: none; //just overriding very strange styles inside _custom.scss
  }
  .prospects-row {
    flex-wrap: none;
    display: block;
  }
  .prospect-container {
    padding: 5px;
    padding-top: 10px;
    //margin-bottom: 10px;
    background: white;
    padding-left: 15px;
    //border-radius: 20px;
    border: 1px solid white;

    .chat-icon {
      font-size: 1.4rem;
    }
  }
  .card .card-body {
    padding: 10px;
  }
  .card-content {
    display: flex;
  }
  .img-wrapper {
    .unread-tag {
    }
  }
  h5 {
    margin-bottom: 0;
    font-size: 1rem;
  }
  .dot {
    height: 10px;
    width: 10px;
    background-color: #6bd098;
    border-radius: 50%;
    position: absolute;
    margin-left: -10px;
  }

  .avatar {
    display: flex;
    flex-flow: column;
    p {
      margin: 0;
    }
    img {
      border-radius: 50px;
      max-width: 50px;
    }
  }
  .maxwidth-avatar {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  .name-headline {
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
  .last-message {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0.5rem;
    p {
      margin: 0;
      margin-bottom: 0px;

      padding-top: 5px;
    }
    .msg-content {
      padding: 5px 0;
    }
    .btn {
      padding: 2px 10px;
    }
  }

  .msg-replied-time {
    text-align: left;
    font-size: 0.5rem;
    margin-top: -5px;
    p {
      margin: 0;
      margin-bottom: 0px;

      padding-top: 5px;
    }
  }

  .tags-content {
    display: flex;
    justify-content: space-between;
  }
  .switch {
  }
  .btn {
    margin: 0;
  }
  .messages {
    max-height: 350px;
    overflow: scroll;
  }
  .send-message {
    display: flex;
    align-items: center;
  }
}
