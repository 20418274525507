.send-msg-sub-container {
  padding: 0px 16px 0px 16px;

.required-subject{
    label{
      color:red;
      margin: 0;
      padding: 0;
    }
    
  }

.send-msg-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  
  .input {
    width: 85%;
  }

  .form-control{
    background-color: #F3F2EE;
    border: 1px solid #e8e7e3;
    border-radius: 4px;
    color: #66615b;
    font-size: 14px;
    padding: 7px 18px;
    // height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .avatar{
    margin: 5px;
    width: 42px;

    img{
      max-width: 100%;
    }
  }
  .reply-error-msg{
    text-transform: capitalize;
    padding-bottom:10px;
  }
}
}
