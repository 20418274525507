img{
    max-width: 100%;
    border-radius: $border-radius-small;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}

.sidenav-logo {
    max-width: none;
    width: 11em;
}

.sidenav-logo-mini {
    display: none;
}

.sidebar-mini {
    .sidenav-logo {
        display: none;
    }

    .sidenav-logo-mini {
        max-width: none;
        width: 2.68em;
        display: block;
        margin-left: .6em;
    }
}
